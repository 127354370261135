import React from 'react'
import { Card, Button } from 'react-bootstrap'

export default function Services({ data }) {
  const services = data.services.map((service, index) => {
    return (
      <Card key={index} className="m-4" style={{ width: '256px', height: "256px" }}>
        <Card.Img variant="top" src={`images/services/${service.image}`} />
        <Card.Body>
          <Card.Title>{service.name}</Card.Title>
          <Card.Text>
            {service.description}
          </Card.Text>
        </Card.Body>
      </Card>)
  })
  return (
    <section id="services">
      <div className="row work">

        <div className="three columns header-col">
          <h1><span>Dienstleistungen</span></h1>
        </div>

        <div className="nine columns main-col">
          <div className="flex flex-row">
            {services}
          </div>

        </div>
      </div>
    </section>
  )
}