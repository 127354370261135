const resume = {
  "main": {
    "image": "profilepic.jpg",
    "bio": "",
    "email": "mail@lindener.it",
    "phone": "+49 175 7388 547",
    "address": {
      "street": "Nölkensweg 2",
      "city": "Hamburg",
      "zip": "22307"
    },
    "website": "https://www.lindener.it",
    "resumedownload": "https://lindener.it/CV_TobiasLindener_de.pdf",
    "social": [
      {
        name: "mail",
        url: "mailto:mail@lindener.it",
        className: "fa fa-envelope"
      },
      {
        name: "xing",
        url: "https://www.xing.com/profile/Tobias_Lindener/cv",
        className: "fa fa-xing"
      },
      {
        "name": "linkedin",
        "url": "https://www.linkedin.com/in/tobiaslindener/",
        "className": "fa fa-linkedin"
      },
      {
        "name": "github",
        "url": "http://github.com/tlindener",
        "className": "fa fa-github"
      }
    ]
  },
  "services": [{
    "name": "IT-Beratung",
    "description": "Von der Idee zur Verwirklichung.",
    "image": "consulting.jpg"
  }, {
    "name": "Sofware Engineering",
    "description": "Entwicklung maßgeschneiderter Lösungen für Ihr Unternehmen",
    "image": "development.webp"
  }],
  "resume": {
    "education": [
      {
        "school": "KTH Royal Institute of Technology Stockholm",
        "degree": "M.Sc. ICT Innovation",
        "graduated": "September 2018",
        "description": ""
      },
      {
        "school": "Universidad Politécnica de Madrid",
        "degree": "M.Sc. Data Science",
        "graduated": "Juni 2017",
        "description": ""
      },
      {
        "school": "Hochschule für Wirtschaft und Recht, Berlin",
        "degree": "B.Sc. Wirtschaftsinformatik",
        "graduated": "September 2014",
        "description": ""
      },
      {
        "school": "Gymnasium zum Altenforst, Troisdorf",
        "degree": "Allgemeine Hochschulreife",
        "graduated": "Juli 2011",
        "description": ""
      }
    ],
    "work": [
      {
        "company": "Zenner IoT Solutions Hamburg",
        "title": "PO & Special Projects",
        "years": "Oktober 2019 - April 2021",
        "descriptions": [
          { text: "Anforderungs- und Projektmanagement für ein breites Spektrum an Kundenprojekten im Bereich der Energieversorger" },
          { text: "Product Owner für die Smart City Plattform IDa sowie für ELEMENT Go, eine Anwendung zur App-gestützten Installation von LoRaWAN Geräten im Feld" },
          { text: "Presales für die Bereiche Smart City und Rollout Digitalisierung" },
          {
            text: "Software Engineer für kundenspezifische Projekte, zum Beispiel Integration von Cloud IoT Plattformen mit der Zenner IoT Plattform oder Mobile Entwicklung für Android",
            tech: "Docker, Kotlin, Typescript, Azure, AWS, Android, Elixir"
          }
        ]
      },
      {
        "company": "Netlight Hamburg",
        "title": "IT-Consultant",
        "years": "September 2018 - September 2019",
        "descriptions": [
          { text: "Software Architekt bei einem Mobility Startup in Hamburg zur Weiterentwicklung der multi-modalen Mobilitätsplattform.", tech: "" },
          { text: "Dies umfasste die Generalisierung der existierenden Plattform zur vereinfachten Anbindung zusätzlicher Mobilitätsanbieter und der Integration in die mobilen B2C Anwendungen.", tech: "AWS ECS, Typescript, PostgreSQL, Teamcity" },
          { text: "Beratung als Enterprise Architect und Lead Software Engineer in einem siebenköpfigen Team bei einem e-Commerce Kunden in Hamburg. Schwerpunkt Modernisierung der Shop Vorsysteme auf Grundlage einer neuen ereignisorientierten Microservice Architektur. Dabei insbesondere Konzeption und Umsetzung einer Event-Stream Architektur auf Basis von Kubernetes und AWS Lambda. Besonderes Augenmerk galt der Kompatibilität und Integration in die bestehende Systemlandschaft. Die Umstellung und Integration mussten fließend passieren, um den Betrieb nicht zu unterbrechen.", tech: "AWS Lambda, K8S, Kotlin, nodeJS, Spring Boot, PostgreSQL, Jenkins" }
        ]
      },
      {
        "company": "RISE SICS Stockholm",
        "title": "Machine Learning Research Intern",
        "years": "Februar 2018 - Juni 2019",
        "descriptions": [
          { text: "Projektsteuerung im Rahmen des pan-europäischen Streamline Projektes", tech: "" },
          { text: "Approximate Distributed Stream Processing mit Apache Flink", tech: "Apache Flink, Docker, AWS" },
        ]
      },
      {
        "company": "Travel Startup Stockholm",
        "title": "Freiberuflicher IT-Berater",
        "years": "Dezember 2017 - Juni 2018",
        "descriptions": [
          { text: "Beratung beim Outsourcing der Lösungsentwicklung mit einem indischen Entwicklerteam. Konzeption der Softwarearchitektur und Begleitung der Umsetzung", tech: "nodeJS, Google Cloud Platform" }
        ]
      },
      {
        "company": "IBM Deutschland",
        "title": "IT-Consultant Internet of Things",
        "years": "Juli 2015 - September 2016",
        "descriptions": [
          { text: "Design und Entwicklung der Sicherheitskomponenten für die IBM Watson IoT Plattform. Insbesondere Implementierung eines weltweit skalierenden Autorisierung Systems auf Ressourcen", tech: "Java, Cassandra, MongoDB, MQTT, IBM Cloud, RTC)" },
          { text: "Konzeption und Umsetzung einer Connected Car Plattform mit einem dreiköpfigen Entwicklerteam", tech: "IBM Cloud, nodeJS, Angular, IBM Watson IoT Platform" },
          { text: "Presales für maßgeschneiderte IoT Lösungen in den Industriebereichen Automotive sowie Home Appliances", tech: "" },
          { text: "Workshops für Best Practices von IoT Architekturen", tech: "" }
        ]
      }
      ,
      {
        "company": "IBM Deutschland",
        "title": "Infrastruktur Architekt für Cloud & Mobility Lösungen",
        "years": "Dezember 2014 - Juni 2016",
        "descriptions": [
          { text: "Systemarchitektur und Projektmanagement bei der Migration einer VDI Lösung vom lokalen Rechenzentrum in die IBM Cloud", tech: "Softlayer, VMWare VDI" },
          { text: "Entwicklung einer Automatisierungslösung zum Disaster Recovery für virtuelle Desktop Umgebungen im Bankensektor", tech: ".net C#, MSSQL, Powershell" }
        ]
      },
      {
        "company": "IBM Deutschland",
        "title": "Dualer Student",
        "years": "Oktober 2011 - September 2014",
        "descriptions": []
      }
    ]
  },
  "portfolio": {
    "projects": [
      {
        title: "Telefonica Join Hackathon",
        category: "Zweiter Platz beim Telefonica Join Hackathon in Madrid",
        image: "join-hackathon.jpg",
        url: "https://www.telefonica.com/es/web/sala-de-prensa/-/telefonica-celebra-un-hackathon-y-premia-las-mejores-ideas-de-los-jovenes-a-traves-de-la-iniciativa-join"
      },
      {
        title: "Le Chef",
        category: "Das persönliche digitale Kochbuch",
        image: "le-chef.png",
        url: "https://www.lechef.app"
      },
      {
        title: "Connected Car Platform",
        category: "Watson IoT Connected Car Demo Projekt",
        image: "connected-car-demo.png",
        url: "https://www.youtube.com/watch?time_continue=1&v=NEmX8XJAEFM"
      }, {
        title: "DB Open Data Hackathon",
        category: "Siegerteam beim vierten DB  Hackathon in Berlin",
        image: "db-hackathon.jpg",
        url: "https://dbmindbox.com/db-opendata-hackathons/hackathons/4-db-hackathon-der-hackday/"
      }

    ]
  }
}

export default resume
