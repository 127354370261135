import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDocker, faNodeJs, faJava, faAws, faGoogle, faAndroid } from '@fortawesome/free-brands-svg-icons'
import { faDatabase, faCode } from '@fortawesome/free-solid-svg-icons'
import KotlinLang from '../Icons/kotlin-lang.svg'
const Chips = ({ work }) => {
  if (work.tech && work.tech !== "") {
    const keywords = work.tech.split(',').map((keyword, index) => {
      return (<Chip key={index} keyword={keyword.trim()} />)
    })
    return (<div className="md-chips">
      {keywords}
    </div>)

  } else {
    return null
  }
}

const Chip = ({ keyword }) => {
  const icon = techToIcon(keyword)
  if (icon !== null) {
    return (<div className="md-chip">
      <div className="md-chip-icon">{icon}</div>
      {keyword}
    </div>)
  } else {
    return (<div className="md-chip">
      {keyword}
    </div>)
  }
}
const techToIcon = (keyword) => {
  switch (keyword.toLowerCase()) {
    case "android":
      return <FontAwesomeIcon icon={faAndroid} />
    case "kotlin":
      return <img src={KotlinLang} className="svg-inline--fa" width="13" />
    case "docker":
      return <FontAwesomeIcon icon={faDocker} />
    case 'nodejs':
      return <FontAwesomeIcon icon={faNodeJs} />
    case 'java':
      return <FontAwesomeIcon icon={faJava} />
    case 'aws':
      return <FontAwesomeIcon icon={faAws} />
    case 'postgresql':
      return <FontAwesomeIcon icon={faDatabase} />
    case 'mssql':
      return <FontAwesomeIcon icon={faDatabase} />
    case 'typescript':
      return <FontAwesomeIcon icon={faCode} />
    case 'mongodb':
      return <FontAwesomeIcon icon={faDatabase} />
    case 'google cloud platform':
      return <FontAwesomeIcon icon={faGoogle} />
    case 'cassandra':
      return <FontAwesomeIcon icon={faDatabase} />

    default:
      return null
  }

}
const Resume = ({ data }) => {
  const education = data.education.map(education => {
    return <div key={education.school}><h3>{education.school}</h3>
      <p className="text-xl">{education.degree} / {education.graduated}</p>
      <p className="text-lg">{education.description}</p></div>
  })
  const work = data.work.map((work, index) => {
    const descriptions = work.descriptions.map((desc, index) => { return <li key={index} className="text-lg">{desc.text}<br></br><i><Chips work={desc} /></i></li> })
    return <div key={index}><h3>{work.company}</h3>
      <p className="text-xl">{work.title} / {work.years}</p>
      <ul>{descriptions}</ul>
    </div>
  })
  return (
    <section id="resume">
      <div className="row work">

        <div className="three columns header-col">
          <h1>Berufliche <span>Erfahrung</span></h1>
        </div>

        <div className="nine columns main-col">
          {work}
        </div>
      </div>
      <div className="row education">
        <div className="three columns header-col">
          <h1><span>Ausbildung</span></h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">
              {education}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}



export default Resume
