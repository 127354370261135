import React from 'react'
import { Link } from 'react-router-dom'
const Footer = ({ data }) => {
  const networks = data.social.map(function (network) {
    return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
  })
  return (
    <footer>

      <div className="row">
        <div className="twelve columns">
          <ul className="social-links">
            {networks}
          </ul>

          <ul className="copyright">
            <li>&copy; Copyright 2020 Tobias Lindener</li>
            <li>Design by <a title="Styleshout" href="http://www.styleshout.com/">Styleshout</a></li>
            <li><Link to="/imprint">Impressum</Link> </li>
          </ul>

        </div>
        <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
      </div>
    </footer>
  )
}

export default Footer
