import React from 'react'
import './App.css'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Resume from './Components/Resume'
import Services from './Components/Services'
import resume from './resumeData'
import About from "./Components/About"

const Main = () => {
  return (
    <div className="App">
      <Header data={resume.main} />
      <div className="container mx-auto max-w-screen-lg">
        <Services data={resume} />
        <Resume data={resume.resume} />
        <Footer data={resume.main} />
      </div>

    </div>
  )
}
export default Main
