import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Switch, Route } from "react-router-dom"
import Imprint from './Components/Imprint'
import Main from './Main'

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Main />
        </Route>
        <Route path="/imprint">
          <Imprint />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}
export default App
